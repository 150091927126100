/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import OutlinedSearchInput from "../../../Components/Form/Inputs/OutlinedSearchInput";
import { useAppDispatch, useAppSelector } from "../../../Redux/app/hooks";
import {
  getStudentsFormDetails,
  selectStudents,
} from "../../../Redux/features/studentsSlice";
import { getAllStudents } from "../../../Apis/Private/students";

import { getReportsFormDetails } from "../../../Redux/features/reportSlice";
import AdmittedStudentFilter from "../../../Components/Filters/AdmittedStudentsFIlter";
import AdmittedStudentsTable from "../../../Tables/AllStudents/AdmittedStudentsTable";
import { toast } from "react-toastify";
const Students = () => {
  const {
    SessionId,
    facultyId,
    DepartmentId,
    PageSize,
    PageNumber,
    SearchPrams,
    LevelId,
  } = useAppSelector(selectStudents);

  const dispatch = useAppDispatch();

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    dispatch(getReportsFormDetails({ name, value }));
    dispatch(getStudentsFormDetails({ name, value }));
  };

  const handleGettingStudents = () => {
    if (SessionId || SearchPrams) {
      dispatch(getStudentsFormDetails({ name: "allStudents", value: null }));
      getAllStudents(
        SessionId,
        LevelId,
        facultyId,
        DepartmentId,
        SearchPrams,
        1,
        PageSize,
        PageNumber
      );
    } else {
      toast.info("Please select at least session to filter");
    }
  };

  useEffect(() => {
    if (SearchPrams || SessionId) {
      getAllStudents(
        SessionId,
        LevelId,
        facultyId,
        DepartmentId,
        SearchPrams,
        1,
        PageSize,
        PageNumber
      );
    }
  }, [PageNumber, PageSize]);

  return (
    <>
      <PageTitle>Admitted Students</PageTitle>
      <ItemsCenterJustifyBtw className="justify-between">
        <OutlinedSearchInput
          placeholder="Search by name or email"
          name="SearchPrams"
          value={SearchPrams}
          onChangeHandler={handleOnChange}
          searchFunction={handleGettingStudents}
        />
        <AdmittedStudentFilter filterFunction={handleGettingStudents} />
      </ItemsCenterJustifyBtw>
      <AdmittedStudentsTable />
    </>
  );
};

export default Students;
