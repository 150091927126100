import { createSlice /*PayloadAction*/ } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

export interface studentsState {
  gettingAllStudents: boolean;
  allStudents: object | null | any;
  gettingAdminStudents: boolean;
  adminStudents: object | null | any;
  gettingAdmittedStudentDetails: boolean;
  gettingAdmittedStudents: boolean;
  admittedStudentsDetails: object | null | any;
  admittedStudents: object | null | any;
  gettingActiveStudents: boolean;
  activeStudents: object | null | any;
  gettingActiveStudentsDownloadable: boolean;
  activeStudentsDownloadable: object | null;
  gettingStudentDetails: boolean;
  studentDetails: object | null | any;
  gettingAdmittedStudentsNumberBySession: boolean;
  admittedStudentsNumberBySession: object | null;
  gettingActiveStudentsNumberBySession: boolean;
  activeStudentsNumberBySession: object | null;
  registeringStudent: boolean;
  deferringStudent: boolean;
  defferedStudents: object | null | any;
  gettingStudentLevel: boolean;
  studentLevel: object | null | any;

  gettingClearance: boolean;
  clearanceList: object | null | any;
  updatingClearance: boolean;
  editingStudentInfo: boolean;
  changingStudentLevel: boolean;
  updatingSession: boolean;
  completingCourseReg: boolean;

  // search states
  SessionId: number | null;
  LevelId: number | null;
  DepartmentId: number | null;
  PageSize: number | null;
  PageNumber: number | null;
  studentType: string | null;
  SearchPrams: string | null;
  StudentType: number | null;
  matricNumber: string | null;
  activationStatus: number | null;

  // student Info
  admissionsId: number;
  prospectiveStudentId: number;
  programmeId: number;
  genderId: number;
  sessionId: number;
  departmentId: number;
  departmentName: string | null;
  facultyId: number | null;
  facultyName: string | null;
  personId: number;
  appicationNumber: string | null;
  admitted: boolean;
  programmeName: string | null;
  firstName: string | null;
  lastName: string | null;
  otherName: string | null;
  mobilePhone: string | null;
  contactAddress: string | null;
  emailAddress: string | null;
  parentName: string | null;
  parentNumber: string | null;
  stateId: string | null;
  stateName: string | null;
  religionId: number;
  religionName: string | null;
  localGovernmentId: string | null;
  localGovernmentName: string | null;
  refferalId: number;
  refererName: string | null;
  advertMeans: string | null;
  hobbies: string | null;
  dateOfBirth: string | null;
  studentId: number | null;
  disabilityId: number;
  studentCurrentLevel: number | null;
  studentCurrentSeesion: number | null;
  tribeId: number | null;
  tribeName: string | null;

  // next of kin details
  nextOfKinFullname: string | null;
  nextOfKinPhoneNo: string | null;
  nextOfKinEmail: string | null;
  nextOfKinAddress: string | null;
  nextOfKinRelationship: string | null;

  // clearance state
  clearanceSession: number | null;
  clearanceSemester: number | null;
  clearanceStartDate: string | null;
  clearanceEndDate: string | null;

  // session state
  sessionName: string | null;
  sessionActivated: boolean;
  sessionAdmitting: boolean;

  // semester state
  semesterId: number | null;

  // table state
  clickedButtonIndex: number | null;
}

const initialState: studentsState = {
  gettingAllStudents: false,
  allStudents: null,
  gettingAdminStudents: false,
  adminStudents: null,
  gettingAdmittedStudentDetails: false,
  gettingAdmittedStudents: false,
  admittedStudentsDetails: null,
  admittedStudents: null,
  gettingActiveStudents: false,
  activeStudents: null,
  gettingActiveStudentsDownloadable: false,
  activeStudentsDownloadable: null,
  gettingStudentDetails: false,
  studentDetails: null,
  gettingAdmittedStudentsNumberBySession: false,
  admittedStudentsNumberBySession: null,
  gettingActiveStudentsNumberBySession: false,
  activeStudentsNumberBySession: null,
  registeringStudent: false,
  editingStudentInfo: false,
  changingStudentLevel: false,
  deferringStudent: false,
  defferedStudents: null,
  gettingStudentLevel: false,
  studentLevel: null,

  gettingClearance: false,
  clearanceList: null,
  updatingClearance: false,
  updatingSession: false,
  completingCourseReg: false,

  // student search
  SessionId: null,
  LevelId: null,
  DepartmentId: null,
  PageSize: 30,
  PageNumber: 1,
  studentType: "GetAdmited",
  SearchPrams: null,
  StudentType: 1,
  matricNumber: null,
  activationStatus: null,

  // student Info
  admissionsId: 0,
  prospectiveStudentId: 0,
  programmeId: 0,
  sessionId: 0,
  genderId: 1,
  departmentId: 0,
  departmentName: null,
  facultyId: null,
  facultyName: null,
  personId: 0,
  appicationNumber: null,
  admitted: false,
  programmeName: null,
  firstName: null,
  lastName: null,
  otherName: null,
  mobilePhone: null,
  contactAddress: null,
  emailAddress: null,
  parentName: null,
  parentNumber: null,
  stateId: null,
  stateName: null,
  religionId: 1,
  religionName: null,
  localGovernmentId: null,
  localGovernmentName: null,
  refferalId: 0,
  refererName: null,
  advertMeans: null,
  hobbies: null,
  dateOfBirth: null,
  tribeId: null,
  tribeName: null,

  studentId: null,
  disabilityId: 1,
  studentCurrentLevel: null,
  studentCurrentSeesion: null,

  // next of kin details
  nextOfKinFullname: null,
  nextOfKinPhoneNo: null,
  nextOfKinEmail: null,
  nextOfKinAddress: null,
  nextOfKinRelationship: null,

  // clearanceStates
  clearanceSession: null,
  clearanceSemester: null,
  clearanceStartDate: null,
  clearanceEndDate: null,

  // session states
  sessionName: null,
  sessionActivated: true,
  sessionAdmitting: true,

  // semester state
  semesterId: null,

  // table state
  clickedButtonIndex: null,
};

export const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    setGettingAdminStudents: (state, action) => {
      state.gettingAdminStudents = action.payload;
    },
    setAdminStudents: (state, action) => {
      state.adminStudents = action.payload;
    },
    setGettingAllStudents: (state, action) => {
      state.gettingAllStudents = action.payload;
    },
    setAllStudents: (state, action) => {
      state.allStudents = action.payload;
    },
    setGettingAdmittedStudentDetails: (state, action) => {
      state.gettingAdmittedStudentDetails = action.payload;
    },
    setAdmittedStudentsDetails: (state, action) => {
      state.admittedStudentsDetails = action.payload;
    },
    setGettingAdmittedStudent: (state, action) => {
      state.gettingAdmittedStudents = action.payload;
    },
    setAdmittedStudents: (state, action) => {
      state.admittedStudents = action.payload;
    },
    setGettingActiveStudents: (state, action) => {
      state.gettingActiveStudents = action.payload;
    },
    setActiveStudents: (state, action) => {
      state.activeStudents = action.payload;
    },
    setGettingActiveStudentsDownloadable: (state, action) => {
      state.gettingActiveStudentsDownloadable = action.payload;
    },
    setActiveStudentsDownloadable: (state, action) => {
      state.activeStudentsDownloadable = action.payload;
    },
    setGettingStudentDetails: (state, action) => {
      state.gettingStudentDetails = action.payload;
    },
    setGettingAdmittedStudentsNumberBySession: (state, action) => {
      state.gettingAdmittedStudentsNumberBySession = action.payload;
    },
    setGettingStudentLevel: (state, action) => {
      state.gettingStudentLevel = action.payload;
    },
    setStudentLevel: (state, action) => {
      state.studentLevel = action.payload;
    },

    setAdmittedStudentsNumberBySession: (state, action) => {
      state.admittedStudentsNumberBySession = action.payload;
    },
    setGettingActiveStudentsNumberBySession: (state, action) => {
      state.gettingActiveStudentsNumberBySession = action.payload;
    },
    setActiveStudentsNumberBySession: (state, action) => {
      state.activeStudentsNumberBySession = action.payload;
    },
    setRegisteringStudent: (state, action) => {
      state.registeringStudent = action.payload;
    },
    setEditingStudentInfo: (state, action) => {
      state.editingStudentInfo = action.payload;
    },
    setChangingStudentLevel: (state, action) => {
      state.changingStudentLevel = action.payload;
    },
    setDifferedStudent: (state, action) => {
      state.defferedStudents = action.payload;
    },
    setDifferingStudent: (state, action) => {
      state.deferringStudent = action.payload;
    },
    setClearanceList: (state, action) => {
      state.clearanceList = action.payload;
    },
    setGettingClearance: (state, action) => {
      state.gettingClearance = action.payload;
    },
    setUpdatingClearance: (state, action) => {
      state.updatingClearance = action.payload;
    },
    setUpdatingSession: (state, action) => {
      state.updatingSession = action.payload;
    },
    setCompletingCourseReg: (state, action) => {
      state.completingCourseReg = action.payload;
    },
    setClickedButtonIndex: (state, action) => {
      state.clickedButtonIndex = action.payload;
    },
    resetStudentParams: (state) => {
      // student search
      state.SessionId = null;
      state.DepartmentId = null;
      state.PageSize = 30;
      state.PageNumber = 1;
      state.studentType = "GetAdmited";
      state.SearchPrams = null;
      state.StudentType = 1;
      state.matricNumber = null;
      state.activationStatus = null;
      state.gettingAllStudents = false;
      state.allStudents = null;

      // student Info
      state.admissionsId = 0;
      state.prospectiveStudentId = 0;
      state.programmeId = 0;
      state.sessionId = 0;
      state.departmentId = 0;
      state.departmentName = null;
      state.facultyId = null;
      state.facultyName = null;
      state.personId = 0;
      state.appicationNumber = null;
      state.admitted = false;
      state.sessionName = null;
      state.programmeName = null;
      state.firstName = null;
      state.lastName = null;
      state.otherName = null;
      state.mobilePhone = null;
      state.contactAddress = null;
      state.emailAddress = null;
      state.parentName = null;
      state.parentNumber = null;
      state.stateName = null;
      state.religionName = null;
      state.localGovernmentName = null;
      state.refferalId = 0;
      state.refererName = null;
      state.advertMeans = null;
      state.LevelId = null;

      state.studentId = null;
      state.studentCurrentLevel = null;
      state.studentCurrentSeesion = null;

      // clearanceStates
      state.clearanceSession = null;
      state.clearanceSemester = null;
      state.clearanceStartDate = null;
      state.clearanceEndDate = null;
      state.allStudents = null;
    },
    setStudentDetails: (state, action) => {
      state.admissionsId = action?.payload?.admissionsId;
      state.prospectiveStudentId = action?.payload?.prospectiveStudentId;
      state.programmeId = action?.payload?.programmeId;
      state.sessionId = action?.payload?.sessionId;
      state.departmentId = action?.payload?.departmentId;
      state.departmentName = action?.payload?.departmentName;
      state.facultyId = action?.payload?.facultyId;
      state.facultyName = action?.payload?.facultyName;
      state.personId = action?.payload?.personId;
      state.appicationNumber = action?.payload?.appicationNumber;
      state.admitted = action?.payload?.admitted;
      state.sessionName = action?.payload?.sessionName;
      state.programmeName = action?.payload?.programmeName;
      state.firstName = action?.payload?.firstName;
      state.lastName = action?.payload?.lastName;
      state.otherName = action?.payload?.otherName;
      state.mobilePhone = action?.payload?.mobilePhone;
      state.contactAddress = action?.payload?.contactAddress;
      state.emailAddress = action?.payload?.emailAddress;
      state.parentName = action?.payload?.nextOfKinFullname;
      state.parentNumber = action?.payload?.nextOfKinPhoneNo;
      state.stateId = action?.payload?.stateId;
      state.stateName = action?.payload?.stateName;
      state.religionId = action?.payload?.religionId;
      state.religionName = action?.payload?.religionName;
      state.localGovernmentId = action?.payload?.localGovernmentId;
      state.localGovernmentName = action?.payload?.localGovernmentName;
      state.refferalId = action?.payload?.refferalId;
      state.refererName = action?.payload?.refererName;
      state.religionId = action?.payload?.religionId;
      state.advertMeans = action?.payload?.advertMeans;
      state.nextOfKinFullname = action?.payload?.nextOfKinFullname;
      state.nextOfKinPhoneNo = action?.payload?.nextOfKinPhoneNo;
      state.nextOfKinAddress = action?.payload?.nextOfKinAddress;
      state.nextOfKinEmail = action?.payload?.nextOfKinEmail;
      state.nextOfKinRelationship = action?.payload?.nextOfKinRelationship;
      state.genderId = action?.payload?.genderId;
      state.disabilityId = action?.payload?.disabilityId;
      state.hobbies = action?.payload?.hobbies;
      state.dateOfBirth = action?.payload?.dateOfBirth;
      state.tribeId = action?.payload?.tribeId;
      state.tribeName = action?.payload?.tribeName;
      state.matricNumber = action?.payload?.matricNumber;
    },
    getStudentsFormDetails: (state, action) => ({
      ...state,
      [action?.payload?.name]: action?.payload?.value,
    }),
  },
});

export const {
  setActiveStudents,
  setActiveStudentsDownloadable,
  setActiveStudentsNumberBySession,
  setAdminStudents,
  setAdmittedStudentsDetails,
  setAdmittedStudents,
  setAdmittedStudentsNumberBySession,
  setEditingStudentInfo,
  setGettingActiveStudents,
  setGettingActiveStudentsDownloadable,
  setGettingActiveStudentsNumberBySession,
  setGettingAdminStudents,
  setGettingAdmittedStudentDetails,
  setGettingAdmittedStudent,
  setGettingAdmittedStudentsNumberBySession,
  setGettingStudentDetails,
  setRegisteringStudent,
  setStudentDetails,
  getStudentsFormDetails,
  setAllStudents,
  setGettingAllStudents,
  setDifferingStudent,
  setDifferedStudent,
  setGettingStudentLevel,
  setStudentLevel,
  setChangingStudentLevel,
  setClearanceList,
  setGettingClearance,
  setUpdatingClearance,
  resetStudentParams,
  setUpdatingSession,
  setCompletingCourseReg,
  setClickedButtonIndex,
} = studentsSlice.actions;
export const selectStudents = (state: RootState) => state.students;

export default studentsSlice.reducer;
