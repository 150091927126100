import { useEffect, useState } from "react";
import { ItemsCenterJustifyBtw, PageTitle } from "../style";
import { PiArrowRightThin } from "react-icons/pi";
import LongWhiteCard from "../../../Components/Layouts/LongWhiteCard";
import LongTransparentCard from "../../../Components/Layouts/LongTransparentCard";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectPayment } from "../../../Redux/features/paymentSlice";
import moment from "moment";
import Loader from "../../../Loaders/MoonLoader";
import { MdOutlineError } from "react-icons/md";
import { BsInfoCircleFill } from "react-icons/bs";
import ReactPrint from "../../../Components/Layouts/ReactPrint";
import {
  Description,
  DirectionContainer,
  ReceiptBody,
  ReceiptContainer,
  ReceiptHeader,
  SchoolName,
  SchoolPhone,
  SectionTitle,
  Table,
  TableData,
  TableHead,
  TableRow,
} from "../Payments/styles";
import { getPaymentReceipt } from "../../../Apis/Private/students";

const StudentPaymentReceipt = () => {
  const [fetchError, seFetchError] = useState();
  const { paymentId } = useParams();
  const { studentPaymentReceipt, gettingStudentPaymentReceipt } =
    useAppSelector(selectPayment);

  // const {}
  useEffect(() => {
    getPaymentReceipt(Number(paymentId), seFetchError);
  }, [paymentId]);

  return (
    <>
      <PageTitle>Payment Receipt</PageTitle>
      <LongTransparentCard>
        <LongWhiteCard>
          {gettingStudentPaymentReceipt ? (
            <Loader size={20} color="#f72585" />
          ) : (
            <>
              {studentPaymentReceipt ? (
                <ReactPrint>
                  <ReceiptContainer>
                    <ReceiptHeader>
                      <img src="/assets/images/PNG/logo.png" alt="logo" />
                      <SchoolName>Godfrey Okoye University (GOUNI)</SchoolName>
                      <SchoolPhone>
                        {
                          studentPaymentReceipt?.payment?.feeSchedule
                            ?.feeScheduleName
                        }{" "}
                        receipt
                      </SchoolPhone>
                    </ReceiptHeader>
                    <img
                      src={studentPaymentReceipt?.payment?.person?.passportUrl}
                      alt="profile"
                      width={200}
                      height={200}
                      style={{ borderRadius: "10px" }}
                    />
                    <ReceiptBody>
                      <ItemsCenterJustifyBtw className="align-start">
                        <div style={{ width: "100%" }}>
                          <SectionTitle>From</SectionTitle>
                          <p>
                            {studentPaymentReceipt?.payment?.person?.firstName +
                              studentPaymentReceipt?.payment?.person?.lastName +
                              studentPaymentReceipt?.payment?.person?.otherName}
                          </p>
                          <p>
                            {
                              studentPaymentReceipt?.payment?.person
                                ?.emailAddress
                            }
                          </p>
                          <p>
                            {
                              studentPaymentReceipt?.payment?.person
                                ?.contactAddress
                            }
                          </p>
                        </div>
                        <PiArrowRightThin size={30} width={100} />
                        <div style={{ width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                            }}
                          >
                            <SectionTitle>To</SectionTitle>
                            <span style={{ textTransform: "uppercase" }}>
                              Godfrey Okoye University
                            </span>
                          </div>
                          <div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <SectionTitle>Payment type: </SectionTitle>
                              <span>
                                {
                                  studentPaymentReceipt?.payment?.feeSchedule
                                    ?.feeScheduleName
                                }
                              </span>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <SectionTitle>
                                Payment ID/Invoice Number:{" "}
                              </SectionTitle>
                              <span>
                                {studentPaymentReceipt?.payment?.invoiceNumber}
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <SectionTitle>Payee ID: </SectionTitle>
                              <span>
                                {
                                  studentPaymentReceipt?.payment?.person
                                    ?.prospectiveStudent?.applicationNumber
                                }
                              </span>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <SectionTitle>Invoice Date: </SectionTitle>
                              <span>
                                {moment(
                                  studentPaymentReceipt?.payment
                                    ?.transactionDate
                                ).format("DD MMM YYYY")}
                              </span>
                            </div>
                            {studentPaymentReceipt?.payment?.session && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <SectionTitle>Session: </SectionTitle>
                                <span>
                                  {
                                    studentPaymentReceipt?.payment?.session
                                      ?.sessionName
                                  }
                                </span>
                              </div>
                            )}
                            {studentPaymentReceipt?.payment?.semesterId && (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "10px",
                                }}
                              >
                                <SectionTitle>semester: </SectionTitle>
                                <span>
                                  {studentPaymentReceipt?.payment
                                    ?.semesterId === 1
                                    ? "First Semester"
                                    : "Second semester"}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </ItemsCenterJustifyBtw>
                      <DirectionContainer>
                        <Description>
                          <BsInfoCircleFill color="red" size={20} />
                          {studentPaymentReceipt?.message}
                        </Description>
                      </DirectionContainer>
                      <Table>
                        <TableRow>
                          <TableHead>Item</TableHead>
                          <TableHead>Quantity</TableHead>
                          <TableHead>Unit Price(#)</TableHead>
                          <TableHead>Total Price(#)</TableHead>
                        </TableRow>
                        <TableRow>
                          {studentPaymentReceipt?.payment?.feeSchedule?.feeDetails?.map(
                            (feeDetail: any) => (
                              <>
                                <TableData>{feeDetail?.feeName}</TableData>
                                <TableData>1</TableData>
                                <TableData>{feeDetail?.feeAmount}</TableData>
                                <TableData>{feeDetail?.feeAmount}</TableData>
                              </>
                            )
                          )}
                        </TableRow>
                      </Table>
                      <Table>
                        <TableRow>
                          <TableHead>SubTotal: </TableHead>
                          <TableData>
                            {studentPaymentReceipt?.payment?.feeSchedule?.feeDetails?.reduce(
                              (accumulator: any, currentValue: any) => {
                                return accumulator + currentValue.feeAmount;
                              },
                              0
                            )}
                          </TableData>
                        </TableRow>
                        <TableRow>
                          <TableHead>VAT: </TableHead>
                          <TableData>0.00</TableData>
                        </TableRow>
                        <TableRow>
                          <TableHead>Total: </TableHead>
                          <TableData>
                            {studentPaymentReceipt?.payment?.feeSchedule?.feeDetails?.reduce(
                              (accumulator: any, currentValue: any) => {
                                return accumulator + currentValue.feeAmount;
                              },
                              0
                            )}
                          </TableData>
                        </TableRow>
                      </Table>
                    </ReceiptBody>
                  </ReceiptContainer>
                </ReactPrint>
              ) : (
                <Description>
                  <MdOutlineError color="red" size={20} />
                  {fetchError ||
                    `Payment receipt not
                  available`}
                </Description>
              )}
            </>
          )}
        </LongWhiteCard>
      </LongTransparentCard>
    </>
  );
};

export default StudentPaymentReceipt;
