import { FC, useEffect } from "react";
import SmallColoredCardWithIcon from "../../../Components/Layouts/SmallColoredCardWithIcon";
import { PiStudentBold } from "react-icons/pi";
import {
  getActiveStudents,
  getAdmmitedStudent,
} from "../../../Apis/Private/students";
import { useAppSelector } from "../../../Redux/app/hooks";
import { selectStudents } from "../../../Redux/features/studentsSlice";

interface SummarySectionProps {
  getSummary: boolean;
}

const SummarySection: FC<SummarySectionProps> = ({ getSummary }) => {
  const {
    gettingAdmittedStudents,
    admittedStudents,
    activeStudents,
    gettingActiveStudents,
  } = useAppSelector(selectStudents);
  const { SessionId } = useAppSelector(selectStudents);

  useEffect(() => {
    SessionId && getAdmmitedStudent(SessionId);
    SessionId && getActiveStudents(SessionId);
  }, [SessionId, getSummary]);
  
  return (
    <div style={{ display: `flex`, alignItems: `center`, gap: `10px` }}>
      <SmallColoredCardWithIcon
        bgColor="var(--clr-pink)"
        icon={<PiStudentBold />}
        title="Admitted Students"
        value={admittedStudents?.totalRecords || 0}
        loading={gettingAdmittedStudents}
      />
      <SmallColoredCardWithIcon
        bgColor="var(--clr-sky-blue)"
        icon={<PiStudentBold />}
        title="Converted Students"
        value={activeStudents?.totalRecords || 0}
        loading={gettingActiveStudents}
      />
    </div>
  );
};

export default SummarySection;
