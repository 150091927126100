import { selectUtil } from "../../Redux/features/utilsSlice";
import { useAppDispatch, useAppSelector } from "../../Redux/app/hooks";
import { filter } from "../../Pages/PrivatePages/util";
import {
  getStudentsFormDetails,
  resetStudentParams,
  selectStudents,
} from "../../Redux/features/studentsSlice";
import ReactSuiteDropdown from "../Form/Dropdowns/NewDropdown";
import { FilterContainer } from "../../Pages/PrivatePages/style";
import FilterButtons from "./FilterButtons";
import { FC } from "react";

interface FilterSectionProps {
  filterFunction?: any;
}

const CourseRegFilter: FC<FilterSectionProps> = ({ filterFunction }) => {
  const { departments, faculties, sessions, semesters } =
    useAppSelector(selectUtil);
  const { SessionId, DepartmentId, facultyId, semesterId } =
    useAppSelector(selectStudents);

  const sessionFilter = filter(sessions, "sessionName", "sessionId");
  const semesterFilter = filter(semesters, "semesterName", "semesterId");
  const facultyFilter = filter(faculties, "facultyName", "facultyId");
  const departmentFilter = filter(
    departments,
    "departmentName",
    "departmentId"
  );
  const dispatch = useAppDispatch();

  const handleSelect = (name: string, value: string | number) => {
    dispatch(getStudentsFormDetails({ name, value }));
  };

  return (
    <FilterContainer className="flex-centered-items">
      <ReactSuiteDropdown
        dropdownItems={facultyFilter}
        title={`Faculty`}
        handleDropdownSelect={handleSelect}
        name="facultyId"
        value={facultyId}
      />
      <ReactSuiteDropdown
        dropdownItems={departmentFilter}
        title={`Department`}
        handleDropdownSelect={handleSelect}
        name="DepartmentId"
        value={DepartmentId}
      />
      <ReactSuiteDropdown
        dropdownItems={sessionFilter}
        title={`Session`}
        handleDropdownSelect={handleSelect}
        name="SessionId"
        value={SessionId}
      />
      <ReactSuiteDropdown
        dropdownItems={semesterFilter}
        title={`Semester`}
        handleDropdownSelect={handleSelect}
        name="semesterId"
        value={semesterId}
      />
      <FilterButtons
        filterFunction={filterFunction}
        resetFilterFunction={() => dispatch(resetStudentParams())}
      />
    </FilterContainer>
  );
};

export default CourseRegFilter;
